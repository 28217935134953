'use client';

import { Spinner } from '@/components/common/spinner';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import dynamic from 'next/dynamic';
import { useCommonTranslation, useInfoTranslation } from '../../hooks/i18n';
const DonationForm = dynamic(() => import('./donation.form'), {
  ssr: false,
  loading: () => <Spinner />,
});

export default function DonationModal() {
  const commonTrans = useCommonTranslation();
  const infoTrans = useInfoTranslation();
  return (
    <Dialog>
      <DialogTrigger className='text-sm hover:underline'>
        {commonTrans('Donate')} Choiee&nbsp;💝
      </DialogTrigger>
      <DialogContent className='flex h-[90%] w-[90%] flex-col items-center justify-center p-12'>
        <h1 className='text-6xl font-extrabold text-primary'>Choiee</h1>
        <h3 className='text-md mt-1 text-zinc-700'>
          {infoTrans('Donate.Thanks')}
        </h3>
        <div className='mt-2 w-full max-w-80 space-y-8'>
          <DonationForm />
        </div>
      </DialogContent>
    </Dialog>
  );
}
